import * as actionTypes from "../lib/constants/actionTypes";
import cookieUtils from "../lib/constants/utils/cookies";
import {ACCESS_TOKEN_KEY} from "../lib/constants/utils/token";
import * as transLogService from "../services/transactionLog";
import axios from "axios";

const _getTransData = data => ({
  type: actionTypes.TRANSACTION_LOG_DATA,
  payload: data
});

const _filterBodyData = data => ({
  type: actionTypes.FILTER_DATA,
  payload: data
});

const _getTransDetails = data => ({
  type: actionTypes.TRANSACTION_DETAILS,
  payload: data
});

const _interfacePaymentCall = data => ({
  type: actionTypes.INTERFACE_PAYMENT,
  payload: data
});

const _downloadTransData = data => ({
  type: actionTypes.TRANSACTION_LOG_DOWNLOAD,
  headers: data.headers,
  payload: data.data,
  status: data.status,
});

const _downloadTransBreakDownData = data => ({
  type: actionTypes.TRANSACTION_BREAK_DOWN_DOWNLOAD,
  headers: data.headers,
  payload: data.data,
  status: data.status,
});

export const getTransData = (filter, req, cancelToken = axios.CancelToken.source()) => {
  return dispatch => {
    let token = {
      Authorization: `Backoffice ${cookieUtils.get(ACCESS_TOKEN_KEY)}`
    };

    return transLogService
      .getTransData(token, filter, req, cancelToken)
      .then(res => {
        if (res.data) {
          return dispatch(_getTransData(res.data));
        }
      })
      .catch(error => {
        if (error.response !== undefined) {
          console.log(error.response);
        }
      });
  };
};

export const getTransDetails = req => {
  return dispatch => {
    let token = {
      Authorization: `Backoffice ${cookieUtils.get(ACCESS_TOKEN_KEY)}`
    };
    return transLogService
      .getTransDetails(token, req)
      .then(res => {
        if (res.data) {
          return dispatch(_getTransDetails(res.data));
        }
      })
      .catch(error => {
        if (error.response !== undefined) {
          console.log(error.response);
        }
      });
  };
};

export const interfacePaymentCall = req => {
  return dispatch => {
    let token = {
      Authorization: `Backoffice ${cookieUtils.get(ACCESS_TOKEN_KEY)}`
    };
    return transLogService
      .interfacePaymentCall(token, req)
      .then(res => {
        if (res.data) {
          return dispatch(_interfacePaymentCall(res.data));
        }
      })
      .catch(error => {
        if (error.response !== undefined) {
          console.log(error.response);
        }
      });
  };
};

export const downloadTransData = (req) => {
  return dispatch => {
    let token = {
      Authorization: `Backoffice ${cookieUtils.get(ACCESS_TOKEN_KEY)}`,
    };

    return transLogService
      .downloadTransData(token, req)
      .then(res => {
        if (res.data) {
          let responseData = {};
          responseData.headers = res.headers;
          responseData.data = res.data;
          responseData.status = res.status;

          return dispatch(_downloadTransData(responseData));
        }
      })
      .catch(error => {
        if (error.response !== undefined) {
          console.log(error.response);
        }
      });
  };
};

export const downloadTransBreakDownData = (req) => {
  return (dispatch) => {
    let token = {
      Authorization: `Backoffice ${cookieUtils.get(ACCESS_TOKEN_KEY)}`,
    };

    return transLogService
      .downloadTransBreakDownData(token, req)
      .then((res) => {
        if (res.data) {
          let responseData = {};
          responseData.headers = res.headers;
          responseData.data = res.data;
          responseData.status = res.status;
          return dispatch(_downloadTransBreakDownData(responseData));
        }
      })
      .catch((error) => {
        if (error.response !== undefined) {
          console.log(error.response);
          const { headers, data, status } = error.response;
          return dispatch(
            _downloadTransBreakDownData({ headers, data, status })
          );
        }
      });
  };
};

export const filterBodyData = filter => {

  return dispatch => {
    return dispatch(_filterBodyData(filter));
  };
};
